import clsx from "clsx";

interface ContainerProps {
  className?: string;
  children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ className, ...props }) => {
  return (
    <div
      className={clsx("mx-auto max-w-7xl px-4 py-2 sm:px-6 lg:px-8", className)}
      {...props}
    />
  );
};

export default Container;
