import { countryToFlag } from "@/constants/countryToFlagMap";
import { User } from "@/types/User";
import { getUploadUrl } from "@/utils/uploadUtils";
import clsx from "clsx";
import Image from "next/image";
import React from "react";

interface AvatarProps {
  user: User;
  size: "sm" | "md" | "lg" | "xl" | "2xl";
}

const defaultClassName =
  "inline-flex items-center justify-center rounded-full bg-gray-500";

const variantSize: Record<string, any> = {
  sm: "h-6 w-6",
  md: "h-8 w-8",
  lg: "h-10 w-10",
  xl: "h-12 w-12",
  "2xl": "h-14 w-14",
};

const variantText: Record<string, any> = {
  sm: "text-xs",
  md: "text-sm",
  lg: "",
  xl: "text-lg",
  "2xl": "text-xl",
};

const variantImgSize: Record<string, any> = {
  sm: { w: 24, h: 24 },
  md: { w: 32, h: 32 },
  lg: { w: 40, h: 40 },
  xl: { w: 48, h: 48 },
  "2xl": { w: 56, h: 56 },
};

const Avatar: React.FC<AvatarProps> = ({ size, user }) => {
  const classes = clsx(defaultClassName, variantSize[size]);
  const classesText = clsx(
    variantText[size],
    "font-medium leading-none text-white"
  );

  const imgSize = variantImgSize[size];
  return (
    <div className="group block flex-shrink-0">
      <div className="flex items-center">
        <div className={classes}>
          {user.profilePhoto ? (
            <div className="relative inline-block">
              <Image
                className={classes}
                src={getUploadUrl(user.profilePhoto)}
                alt={user.firstName}
                width={imgSize.w}
                height={imgSize.h}
              />
              {user.shareLocation && user.countryCode && (
                <span className="absolute -bottom-2 -right-2 block text-xl">
                  {countryToFlag[user.countryCode]}
                </span>
              )}
            </div>
          ) : (
            <span className={classesText}>
              {user.firstName[0].toUpperCase()}
            </span>
          )}
        </div>
        {/* <div className="ml-4">
          <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
            {name}
          </p>
          <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
            View profile
          </p>
        </div> */}
      </div>
    </div>
  );
};

Avatar.displayName = "Avatar";

export default Avatar;
