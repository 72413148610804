import { User } from "@/types/User";

export const NAV_LINKS = [
  {
    label: "Cursos",
    href: "/cursos",
  },
  {
    label: "Blog",
    href: "/blog",
  },
  {
    label: "Ejercicios",
    href: "/ejercicios",
  },
];

export const USER_NAVIGATION = [
  {
    name: "Perfil",
    icon: "UserIcon",
    href: (user: User) => `/perfiles/${user.profileUrl}`,
    action: null,
  },
  {
    name: "Configuraciones",
    icon: "Cog8ToothIcon",
    href: () => "/configuraciones",
  },
  {
    name: "Cerrar Sesión",
    icon: "ArrowRightOnRectangleIcon",
    href: null,
    action: "logout",
  },
];
