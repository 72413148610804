import { USER_NAVIGATION } from "@/constants/navigation";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import Link from "next/link";
import { Fragment } from "react";
import { useAuth } from "src/contexts/AuthContext";
import Avatar from "../Avatar";
import DynamicHeroIcon from "../DynamicHeroIcon";

const HeaderProfileDropdown = () => {
  const { user, logout } = useAuth();

  // Logout on button click
  const onLogout = (e: any) => {
    e.preventDefault();
    logout();
  };

  if (!user) return null;
  return (
    <>
      {/* Profile dropdown */}
      <Menu as="div" className="relative">
        <Menu.Button className="-m-1.5 flex items-center p-1.5">
          <span className="sr-only">Open user menu</span>
          <Avatar user={user} size="md" />
          <span className="hidden lg:flex lg:items-center">
            <span
              className="ml-4 text-sm font-semibold leading-6 text-gray-900"
              aria-hidden="true"
            >
              {user.firstName}
            </span>
            <ChevronDownIcon
              className="ml-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2.5 w-auto min-w-[180px] origin-top-right divide-y rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            {USER_NAVIGATION.map(({ name, action, href, icon }) => (
              <Menu.Item key={name}>
                <Menu.Item>
                  {({ active }) => {
                    if (href) {
                      return (
                        <Link
                          href={href(user)}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm"
                          )}
                        >
                          <DynamicHeroIcon
                            icon={icon}
                            className="mr-3 h-5 w-5 text-primary group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          {name}
                        </Link>
                      );
                    } else if (action) {
                      return (
                        <button
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex w-full items-center px-4 py-2 text-sm"
                          )}
                          onClick={onLogout}
                        >
                          <DynamicHeroIcon
                            icon={icon}
                            className="mr-3 h-5 w-5 text-primary group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          {name}
                        </button>
                      );
                    }
                    return <></>;
                  }}
                </Menu.Item>
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default HeaderProfileDropdown;
