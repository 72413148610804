import { Media } from "@/types/Media";

export const getUploadUrl = (media: Media) => {
  if (media.url.includes("http")) {
    return media.url;
  } else {
    // remove first slash from medial url\
    const url = media.url.replace(/^\//, "");

    return `${process.env.NEXT_PUBLIC_CMS_URL}/${url}`;
  }
};
