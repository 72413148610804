import { Popover } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import Avatar from "src/components/atoms/Avatar";
import Container from "src/components/atoms/Container";
import DynamicHeroIcon from "src/components/atoms/DynamicHeroIcon";
import HeaderProfileDropdown from "src/components/atoms/HeaderProfileDropdown";
import { Logo } from "src/components/atoms/Logo";
import NavLinks from "src/components/molecules/NavLinks";
import { useAuth } from "src/contexts/AuthContext";

function MenuIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
      <path
        d="M5 6h14M5 18h14M5 12h14"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ChevronUpIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
      <path
        d="M17 14l-5-5-5 5"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function MobileNavLink({ children, icon, ...props }: any) {
  return (
    <Popover.Button
      as={Link}
      className=" flex flex-row items-center text-base leading-7 tracking-tight text-gray-700"
      {...props}
    >
      {icon && (
        <DynamicHeroIcon
          icon={icon}
          className="mr-3 h-5 w-5 text-primary group-hover:text-gray-500"
          aria-hidden="true"
        />
      )}
      {children}
    </Popover.Button>
  );
}

const Header = () => {
  const { user, logout } = useAuth();
  return (
    <header className="sticky top-0 z-50 bg-white">
      <nav>
        <Container className="relative z-20 flex w-full justify-between py-8">
          <div className="relative z-20 flex items-center gap-16">
            <Link href="/" aria-label="Home">
              <Logo />
            </Link>
            <div className="flex gap-10">
              <div className="hidden lg:flex lg:gap-10">
                <NavLinks />
              </div>
            </div>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {user ? (
              <HeaderProfileDropdown />
            ) : (
              <Link
                href="/login"
                className="relative -mx-3 -my-2 rounded-lg bg-dark px-3 py-2 text-sm text-white transition-colors delay-150 hover:text-gray-200 hover:delay-[0ms]"
                prefetch={false}
              >
                Login
              </Link>
            )}
          </div>
          <div className="ml-4 flex items-center justify-end gap-6">
            <Popover className="lg:hidden">
              {({ open }) => (
                <>
                  <Popover.Button
                    className="relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 [&:not(:focus-visible)]:focus:outline-none"
                    aria-label="Toggle site navigation"
                  >
                    {({ open }) =>
                      open ? (
                        <ChevronUpIcon className="h-6 w-6" />
                      ) : (
                        <MenuIcon className="h-6 w-6" />
                      )
                    }
                  </Popover.Button>
                  <AnimatePresence initial={false}>
                    {open && (
                      <>
                        <Popover.Overlay
                          static
                          as={motion.div}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                        />
                        <Popover.Panel
                          static
                          as={motion.div}
                          initial={{ opacity: 0, y: -32 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{
                            opacity: 0,
                            y: -32,
                            transition: { duration: 0.2 },
                          }}
                          className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                        >
                          <div className="space-y-4">
                            <MobileNavLink icon="BookOpenIcon" href="/cursos">
                              Cursos
                            </MobileNavLink>
                            <MobileNavLink
                              icon="BookOpenIcon"
                              href="/ejercicios"
                            >
                              Ejercicios
                            </MobileNavLink>
                            <MobileNavLink icon="PencilSquareIcon" href="/blog">
                              Blog
                            </MobileNavLink>
                            <hr />
                            {user ? (
                              <>
                                <MobileNavLink
                                  href={`/perfiles/${user.profileUrl}`}
                                >
                                  <div className="flex flex-row space-x-4">
                                    <Avatar user={user} size="lg" />
                                    <div>
                                      {user.firstName} {user.lastName}
                                    </div>
                                  </div>
                                </MobileNavLink>
                                <MobileNavLink
                                  icon="Cog8ToothIcon"
                                  href="/configuraciones"
                                >
                                  Configuraciones
                                </MobileNavLink>
                                <Popover.Button
                                  as="button"
                                  className=" flex flex-row items-center text-base leading-7 tracking-tight text-gray-700"
                                  onClick={(e) => logout()}
                                >
                                  <DynamicHeroIcon
                                    icon="ArrowRightOnRectangleIcon"
                                    className="mr-3 h-5 w-5 text-primary group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                  Cerrar Sesión
                                </Popover.Button>
                              </>
                            ) : (
                              <MobileNavLink
                                href="/login"
                                icon="ArrowRightOnRectangleIcon"
                              >
                                Login
                              </MobileNavLink>
                            )}
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Popover>
          </div>
        </Container>
      </nav>
    </header>
  );
};

export default Header;
