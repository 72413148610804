type CountryToFlagMap = { [key: string]: string };
export const countryToFlag: CountryToFlagMap = {
  AD: "🇦🇩",
  AR: "🇦🇷",
  AU: "🇦🇺",
  AT: "🇦🇹",
  BE: "🇧🇪",
  BO: "🇧🇴",
  BR: "🇧🇷",
  BG: "🇧🇬",
  CA: "🇨🇦",
  CL: "🇨🇱",
  CO: "🇨🇴",
  CR: "🇨🇷",
  HR: "🇭🇷",
  CY: "🇨🇾",
  CZ: "🇨🇿",
  DK: "🇩🇰",
  DO: "🇩🇴",
  EC: "🇪🇨",
  EE: "🇪🇪",
  FI: "🇫🇮",
  FR: "🇫🇷",
  DE: "🇩🇪",
  GR: "🇬🇷",
  GT: "🇬🇹",
  HN: "🇭🇳",
  HK: "🇭🇰",
  HU: "🇭🇺",
  IS: "🇮🇸",
  IE: "🇮🇪",
  IL: "🇮🇱",
  IT: "🇮🇹",
  JP: "🇯🇵",
  LV: "🇱🇻",
  LI: "🇱🇮",
  LT: "🇱🇹",
  LU: "🇱🇺",
  MT: "🇲🇹",
  MX: "🇲🇽",
  MC: "🇲🇨",
  NL: "🇳🇱",
  NZ: "🇳🇿",
  NI: "🇳🇮",
  NO: "🇳🇴",
  PA: "🇵🇦",
  PY: "🇵🇾",
  PE: "🇵🇪",
  PL: "🇵🇱",
  PT: "🇵🇹",
  PR: "🇵🇷",
  RO: "🇷🇴",
  RU: "🇷🇺",
  SM: "🇸🇲",
  RS: "🇷🇸",
  SK: "🇸🇰",
  SI: "🇸🇮",
  ES: "🇪🇸",
  SE: "🇸🇪",
  CH: "🇨🇭",
  TW: "🇹🇼",
  TR: "🇹🇷",
  UA: "🇺🇦",
  GB: "🇬🇧",
  US: "🇺🇸",
  UY: "🇺🇾",
};
